import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';

import { User } from '../models/User';
import { ColumnsType } from 'antd/lib/table';
import { LinkStyle, tableStyle } from '../style/antdCssProperty';
import { UserOrigin } from '../models/Enum';
import { handleSignUpPath } from 'libs/helper';

interface IProps {
  users: User[];
}

const UserList: FC<IProps> = ({ users }) => {
  const columns: ColumnsType<User> = [
    {
      title: 'NO',
      dataIndex: 'id',
    },
    {
      title: '이름',
      render: (record: User) => {
        return <Link to={`customer/${record.id}`} style={LinkStyle}>{record.name}</Link>;
      },
    },
    {
      title: '이메일',
      dataIndex: 'email',
    },
    {
      title: '가입 사이트',
      render: (value, record) => record.origin === UserOrigin.K ? '킨더' : '초등',
    },
    {
      title: '가입경로',
      render: (value, record) => handleSignUpPath(record.reasonType, record.signupPathMemo),
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
    },
    {
      title: '등록자녀',
      render: (value, record) => (
        record.studentName ? record.studentName : '자녀 미등록'
      ),
    },
    {
      title: '상태',
      render: (value, record) => (
        record.userSubscriptionId ? '수강중' : '-'
      ),
    },
  ];

  return (
    <Table
      dataSource={users}
      columns={columns}
      size='small'
      pagination={false}
      rowKey={(record) => `${record.id}-${record.studentName}`}
      style={tableStyle}
    />
  );
};

export default UserList;
